<div class="w-full h-full bg-gray-200">
  <div class="flex flex-no-wrap">
    <!-- 
      Sidebar starts:
         Mobile responsive sidebar
    -->
    <div *ngIf="isMenu" class="w-full h-screen z-40 lg:hidden fixed" id="mobile-nav">
      <div class="bg-gray-800 opacity-50 fixed h-full w-full lg:hidden overflow-hidden"
        (click)="isMenu = false; isNav = false; isNavChange.emit(false)">
      </div>
      <div
        class="!fixed z-40 sm:relative w-64 md:w-96 shadow bg-primary-900 lg:hidden transition duration-150 ease-in-out h-full">
        <div class="flex flex-col justify-between h-full w-full" style="max-height: calc(100vh - 1rem);overflow-y: auto;">
          <div>
            <div class="flex items-center justify-between px-8 sm:px-4">
              <div class="h-20 w-full flex items-center">
                <div role="img" [routerLink]="['/']">
                  <img class="md:w-40 sm:w-32 sm:h-8 h-10" alt="Live Ethio Logo" title="Live Ethio Logo" src="../../../../../assets/icons/logo_white.png"
                  />
                </div>
              </div>
              <div id="closeSideBar" class="flex items-center justify-center h-10 w-10"
                (click)="isMenu = false; isNav = false; isNavChange.emit(false)">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="20" height="20"
                  viewBox="0 0 24 24" stroke-width="1.5" stroke="white" fill="none" stroke-linecap="round"
                  stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </div>
            </div>
            <!--
              Menu items
            -->
            <ul aria-orientation="vertical">
              <a routerLink="/" target="_blank">
                <li class="pl-6 cursor-pointer text-gray-400 text-sm leading-3 tracking-normal py-4 hover:text-primary-500 hover:bg-gray-50 border-b border-b-gray-500 border-opacity-20">
                    <span class="ml-2 md:text-xl text-base">Home</span>
                </li>
              </a>
              <li class="pl-6 cursor-pointer text-gray-400 text-sm leading-3 tracking-normal py-4 border-b border-b-gray-500 border-opacity-20">
                <div class="flex items-center gap-3 hover:text-primary-500" (click)="oDropdown2 = !oDropdown2">
                  <span class="ml-2 md:text-xl text-base">Rent</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                </div>
                <ul class="pl-6 pt-3" *ngIf="oDropdown2">
                  <a routerLink="/site/property/apartment-for-rent" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">
                        Apartment For Rent
                    </li>
                  </a>
                  <a routerLink="/site/property/house-for-rent" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">
                      House For Rent
                    </li>
                  </a>
                  <a routerLink="/site/property/condo-for-rent" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">
                      Condominium For Rent
                    </li>
                  </a>
                </ul>
              </li>
              <li class="pl-6 cursor-pointer text-gray-400 text-sm leading-3 tracking-normal py-4 border-b border-b-gray-500 border-opacity-20">
                <div class="flex items-center gap-3 hover:text-primary-500" (click)="oDropdown1 = !oDropdown1">
                  <span class="ml-2 md:text-xl text-base">Buy</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                </div>
                <ul class="pl-6 pt-3" *ngIf="oDropdown1">
                  <a routerLink="/site/property/apartment-for-sale" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">
                      Apartment For Sale
                    </li>
                  </a>
                  <a routerLink="/site/property/house-for-sale" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">
                      House For Sale
                    </li>
                  </a>
                  <a routerLink="/site/property/condo-for-sale" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">
                      Condominium For Sale
                    </li>
                  </a>
                  <a routerLink="/site/property/land-for-sale" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">
                      Land For Sale
                    </li>
                  </a>
                  <a routerLink="/site/property/villa-for-sale" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">
                      Villa For Sale
                    </li>
                  </a>
                </ul>
              </li>
              <a routerLink="/site/property" target="_blank">
                <li class="pl-6 cursor-pointer text-gray-400 text-sm leading-3 tracking-normal py-4 hover:text-primary-500 hover:bg-gray-50 border-b border-b-gray-500 border-opacity-20">
                  <span class="ml-2 md:text-xl text-base">All Properties</span>
                </li>
              </a>
              <li class="pl-6 cursor-pointer text-gray-400 text-sm leading-3 tracking-normal py-4 border-b border-b-gray-500 border-opacity-20">
                <div class="flex items-center gap-3 hover:text-primary-500" (click)="oDropdown3 = !oDropdown3">
                  <span class="ml-2 md:text-xl text-base">Invest</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                </div>
                <ul class="pl-6 pt-3" *ngIf="oDropdown3">
                  <!-- <a routerLink="/site/invest/dubai" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">
                      Dubai
                    </li>
                  </a> -->
                  <a routerLink="/site/property/type/new-development" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">
                      New Development
                    </li>
                  </a>
                </ul>
              </li>
              <a routerLink="/site/blog" target="_blank">
                <li class="pl-6 cursor-pointer text-gray-400 text-sm leading-3 tracking-normal py-4 hover:text-primary-500 hover:bg-gray-50 border-b border-b-gray-500 border-opacity-20">
                  <span class="ml-2 md:text-xl text-base">Ethiopian Real Estate News</span>
                </li>
              </a>
              <li
                class="pl-6 cursor-pointer text-gray-400 text-sm leading-3 tracking-normal py-4 border-b border-b-gray-500 border-opacity-20">
                <div class="flex items-center gap-3 hover:text-primary-500" (click)="oDropdown = !oDropdown">
                  <span class="ml-2 md:text-xl text-base">Others</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                </div>
                <ul class="pl-6 pt-3" *ngIf="oDropdown">
                  <a routerLink="/site/about-us" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm"> About Us</li>
                  </a>
                  <a routerLink="/site/contact-us" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">Contact Us</li>
                  </a>
                  <a routerLink="/site/advisory" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">Advisory</li>
                  </a>
                  <a routerLink="/site/property-management" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">Property Management</li>
                  </a>
                  <a routerLink="/site/loans" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">Loans</li>
                  </a>
                  <a routerLink="/site/mortgage-calculator" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">Mortgage Calculator</li>
                  </a>
                  <a routerLink="/site/sitemap" target="_blank">
                    <li class="hover:text-primary-500 hover:bg-gray-50 py-2 px-3 md:text-base sm:text-sm">Sitemap</li>
                  </a>
                </ul>
              </li>
              <li *ngIf="!oDropdown" class="text-primary-500 flex gap-2 cursor-pointer text-base pl-6 pt-8">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                </svg>
                <a class="text-gray-200 hover:text-primary-500" href="tel:+251974299472">+251974299472</a>
              </li>
            </ul>
          </div>
          <div class="w-full">
            <div class="w-full px-4"> 
              <button [routerLink]="['/dashboard/create-listing']"
                class="w-full items-center justify-between rounded-md my-3 flex bg-gray-100 px-3.5 py-2 hover:bg-gray-300">
                <span class="text-sm text-primary-900 font-semibold">Post Property</span>
                <div class="ml-2 bg-primary-500 px-2 rounded-md text-white">Free</div>
              </button>
            </div>
            <!--
              Redirects to 'dashboard' if user is logged in
            -->
            <div class="border-t border-gray-600">
              <div class="w-full flex items-center px-6 py-3" routerLink="/dashboard">
                <div class="w-10 shrink-0" *ngIf="isloggedin(); else profileAvatar">
                  <img *ngIf="user?.profile_picture![0]; else noPicture" class="rounded-md h-10 w-10 object-cover"
                    src="{{user?.profile_picture![0].url}}" alt="" />
                  <ng-template #noPicture>
                    <img class="rounded-md h-10 w-10 object-cover" src="../../../../../assets/images/profile-avatar.png"
                      alt="" />
                  </ng-template>
                </div>
                <ng-template #profileAvatar>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="white" class="w-10 h-10">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </ng-template>
                <p *ngIf="user?.username ; else signIn"
                  class="text-xl text-gray-400  hover:text-primary-500 leading-4 ml-4">{{user?.username}}
                </p>
                <ng-template #signIn>
                  <p class="md:text-xl text-white hover:text-primary-500 text-base leading-4 ml-2">Log In</p>
                </ng-template>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
    <!--
      Mobile responsive sidebar:
        Sidebar ends
    -->
  </div>
</div>

 <!-- 
      Navbar starts:
         Laptop / tablet responsive 
    -->
<nav class="w-full bg-primary-900 py-4">
  <div class="lg:py-0 mx-auto px-6 flex items-center ">
    <div *ngIf="backButton" class="block lg:hidden" (click)="goBack()">
      <button class="cursor-pointer duration-200 hover:scale-125 active:scale-100" title="Go Back">
        <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" class="stroke-white">
          <path stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" d="M11 6L5 12M5 12L11 18M5 12H19"></path>
        </svg>
      </button>
    </div>
    <div role="img" [routerLink]="['/']">
      <img class="w-40 h-10" alt="Live Ethio Logo" title="Live Ethio Logo" src="../../../../../assets/icons/logo_white.png" />
    </div>
    <div class="grid grid-cols-2 gap-4 lg:gap-2 items-center lg:flex ml-auto">
      <div class="ml-auto">
        <button (click)="isMenu = true; isNav = true; isNavChange.emit(true)" class="block lg:hidden ">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>
        <div class="lg:block hidden">
          <!--
             Menu items
          -->
          <ul
            class="flex gap-4 xl:gap-7 text-xs lg:text-2xs text-white bg-primary-900 lg:text-base items-center lg:flex flex-col lg:flex-row justify-center fixed lg:relative top-0 bottom-0 left-0 right-0 z-20">
            <li target="_blank" class="text-gray-300 hover:text-gray-50 cursor-pointer text-base pt-10 lg:pt-0">
              <a [routerLink]="['/']">Home</a>
            </li>
            <li class="text-gray-300 hover:text-gray-50 cursor-pointer text-base pt-10 lg:pt-0">
              <div (mouseenter)="openMenu(1)" (mouseleave)="closeMenu()" class="flex" data-menu>
              <span>Rent</span>
              <div class="cursor-pointer text-gray-600 ml-1 mt-1">
                <svg xmlns="http://www.w3.org/2000/svg"
                  [ngClass]="isOpenedList === 1 ? ' hidden ' : '  icon icon-tabler icon-tabler-chevron-up'" width="20"
                  height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <polyline points="6 9 12 15 18 9" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                  [ngClass]="isOpenedList === 1 ? ' icon icon-tabler icon-tabler-chevron-down ' : ' hidden'"
                  width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z"/>
                  <polyline points="6 15 12 9 18 15"/>
                </svg>
              </div>
            </div>
            <ul (mouseenter)="openMenu(1)" (mouseleave)="closeMenu()"
                [ngClass]="isOpenedList === 1 ? 'visible transition duration-300 opacity-100 bg-primary-900 shadow rounded pt-5 w-48 absolute' : 'hidden'">
                <a routerLink="/site/property/apartment-for-rent" target="_blank">
                  <li class="cursor-pointer text-gray-300  text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                    Apartment For Rent
                  </li>
                </a>
                <a routerLink="/site/property/house-for-rent" target="_blank">
                  <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                    House For Rent
                  </li>
                </a>
                <a routerLink="/site/property/condo-for-rent" target="_blank">
                  <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                    Condominium For Rent
                  </li>
                </a>
                <a routerLink="/site/property/villa-for-rent" target="_blank">
                  <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                    Villa For Rent
                  </li>   
                </a>
              </ul>
            </li>
            <li class="text-gray-300 hover:text-gray-50 cursor-pointer text-base pt-10 lg:pt-0">
              <div (mouseenter)="openMenu(2)" (mouseleave)="closeMenu()" class="flex" data-menu>
              <span>Buy</span>
              <div class="cursor-pointer text-gray-600 ml-1 mt-1">
                <svg xmlns="http://www.w3.org/2000/svg"
                  [ngClass]="isOpenedList === 2 ? ' hidden ' : '  icon icon-tabler icon-tabler-chevron-up'" width="20"
                  height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <polyline points="6 9 12 15 18 9" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                  [ngClass]="isOpenedList === 2 ? ' icon icon-tabler icon-tabler-chevron-down ' : ' hidden'"
                  width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z"/>
                  <polyline points="6 15 12 9 18 15"/>
                </svg>
              </div>
            </div>
            <ul (mouseenter)="openMenu(2)" (mouseleave)="closeMenu()"
                [ngClass]="isOpenedList === 2 ? 'visible transition duration-300 opacity-100 bg-primary-900 shadow rounded pt-5 w-48 absolute' : 'hidden'">
              <a routerLink="/site/property/apartment-for-sale" target="_blank">
                <li class="cursor-pointer text-gray-300  text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                  Apartment For Sale
                </li>
              </a>
              <a routerLink="/site/property/house-for-sale" target="_blank">
                <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                  House For Sale
                </li>
              </a>
              <a routerLink="/site/property/condo-for-sale" target="_blank">
                <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                  Condominium For Sale
                </li>
              </a>
              <a routerLink="/site/property/villa-for-sale" target="_blank">
                <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal rounded-b">
                  Villa For Sale
                </li>
              </a>
              <a routerLink="/site/property/land-for-sale" target="_blank">
                <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal rounded-b">
                  Land For Sale
                </li>
              </a>
              </ul>
            </li>
            <a routerLink="/site/property" target="_blank">
              <li class="cursor-pointer text-gray-300 hover:text-gray-50 text-sm leading-3 tracking-normal py-3 px-3 font-normal">
                All Properties
              </li>
            </a>
            <li class="text-gray-300 hover:text-gray-50 cursor-pointer text-base pt-10 lg:pt-0">
              <div (mouseenter)="openMenu(4)" (mouseleave)="closeMenu()" class="flex" data-menu>
              <span>Invest</span>
              <div class="cursor-pointer text-gray-600 ml-1 mt-1 ">
                <svg xmlns="http://www.w3.org/2000/svg"
                  [ngClass]="isOpenedList === 4 ? ' hidden ' : '  icon icon-tabler icon-tabler-chevron-up'" width="20"
                  height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <polyline points="6 9 12 15 18 9" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg"
                  [ngClass]="isOpenedList === 4 ? ' icon icon-tabler icon-tabler-chevron-down ' : ' hidden'"
                  width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z"/>
                  <polyline points="6 15 12 9 18 15"/>
                </svg>
              </div>
            </div>
            <ul (mouseenter)="openMenu(4)" (mouseleave)="closeMenu()"
                [ngClass]="isOpenedList === 4 ? 'visible transition duration-300 bg-primary-900  opacity-100 shadow rounded pt-5 w-48 absolute' : 'hidden'">
                <!-- <a routerLink="/site/invest/dubai" target="_blank">
                  <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                    Dubai
                  </li>
                </a> -->
                <a routerLink="/site/property/type/new-development" target="_blank">
                  <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                    New Development
                  </li>
                </a>
              </ul>
            </li>
            <li class="text-gray-300 hover:text-gray-50 cursor-pointer text-base pt-10 lg:pt-0">
              <div class="relative">
                <div (mouseenter)="openMenu(3)" (mouseleave)="closeMenu()" class="flex" data-menu>
                  <span>Others</span>
                  <div class="cursor-pointer text-gray-600 ml-1 mt-1">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      [ngClass]="isOpenedList === 3 ? ' hidden ' : '  icon icon-tabler icon-tabler-chevron-up'" width="20"
                      height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <polyline points="6 9 12 15 18 9" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg"
                      [ngClass]="isOpenedList === 3 ? ' icon icon-tabler icon-tabler-chevron-down ' : ' hidden'"
                      width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z"/>
                      <polyline points="6 15 12 9 18 15"/>
                    </svg>
                  </div>
                </div>
              </div>
              <ul (mouseenter)="openMenu(3)" (mouseleave)="closeMenu()"
                [ngClass]="isOpenedList === 3 ? 'visible transition duration-300 opacity-100 bg-primary-900 shadow rounded pt-5 w-48 absolute' : 'hidden'">
                <a routerLink="/site/about-us" target="_blank">
                  <li class="cursor-pointer text-gray-300  text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                    About Us
                  </li>
                </a>
                <a routerLink="/site/advisory" target="_blank">
                  <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                    Advisory
                  </li>
                </a>
                <a routerLink="/site/contact-us" target="_blank">
                  <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                    Contact Us
                  </li>
                </a>
                <a routerLink="/site/loans" target="_blank">
                  <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                    Loans
                  </li> 
                </a> 
                <a routerLink="/site/mortgage-calculator" target="_blank">
                  <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal rounded-b">
                    Mortgage Calculator
                  </li>
                </a>
                <a routerLink="/site/property-management" target="_blank">
                  <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal rounded-b">
                    Property Management
                  </li>
                </a>
                <a routerLink="/site/blog" target="_blank">
                  <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                    Blog
                  </li> 
                </a>
                <a routerLink="/site/sitemap" target="_blank">
                  <li class="cursor-pointer text-gray-300 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 hover:text-gray-600 px-3 font-normal">
                    Sitemap
                  </li>
                </a>
              </ul>
            </li>
            <li class="text-white hover:text-primary-500 flex gap-2 cursor-pointer text-base pt-10 lg:pt-0">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
              </svg>
              <a href="tel:+251974299472">+251974299472</a>
            </li>
          </ul>
        </div>
      </div>
      <svg routerLink="/dashboard" *ngIf="!isloggedin()" xmlns="http://www.w3.org/2000/svg" fill="none"
        viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-8 h-8">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
      </svg>
      <button [routerLink]="['/dashboard/create-listing']"
        class="items-center rounded-md hidden lg:flex bg-gray-100 px-3.5 py-2 hover:bg-gray-300">
        <span class="text-sm text-primary-900 font-semibold">Post Property</span>
        <div class="ml-2 bg-primary-500 px-2 rounded-md text-white">Free</div>
      </button>
      <!--
        Redirects to 'Dashboard' if user is logged in
      -->
      <div class="w-10 shrink-0" *ngIf="isloggedin()">
        <div class="relative">
          <button (click)="toggleMenu()" class="relative flex rounded-full bg-gray-800 text-sm" type="button"
            aria-expanded="false" aria-haspopup="true">
            <span class="sr-only">Open user menu</span>
            <img *ngIf="user?.profile_picture![0]" style="object-fit: cover;" clickOutside (clickOutside)="isMenuOpen = false"
              class="h-9 w-9 rounded-md" src="{{user?.profile_picture![0].url}}" alt="" />
            <img *ngIf="!user?.profile_picture![0]" style="object-fit: cover;" clickOutside (clickOutside)="isMenuOpen = false"
              class="h-9 w-9 rounded-md" src="../../../../../../assets/images/profile-avatar.png" alt="" />
          </button>
          <div [ngClass]="
              isMenuOpen
                ? 'pointer-events-auto scale-100 animate-fade-in-up opacity-100 duration-200'
                : 'pointer-events-none scale-95 opacity-0 duration-100 ease-in'
          "
          class="z-50 absolute right-0 mt-4 w-60 origin-top-right transform rounded-md py-4 dark:shadow-lg shadow-custom ring-1 ring-transparent ring-opacity-5 transition focus:outline-none bg-primary-900">
            <div class="flex-row flex items-center px-4 pb-4">
              <div class="w-10 shrink-0">
                <img *ngIf="user?.profile_picture![0]" style="object-fit: cover;" class="rounded-md h-10 w-10"
                  src="{{user?.profile_picture![0].url}}" alt="user-profile-picture" />
              </div>
              <div class="overflow-hidden px-2 text-sm font-medium text-gray-50">
                {{user?.firstname}} {{user?.lastname}}
                <p class="truncate text-ellipsis text-xs font-medium text-night-300">{{user?.email}}</p>
              </div>
            </div>
            <div class="border-b border-dashed border-night-400"></div>
            <ul class="my-2 mx-4">
              <li routerLink="/dashboard/profile"
                target="_blank"
                class="block cursor-pointer rounded-md px-3 py-2 text-sm font-medium text-gray-200 hover:bg-night-500">
                <div class="flex items-center ml-auto">
                  <img src="assets/icons/outline/user-circle.svg" class="h-5 w-5 mr-2 filter invert" alt="Search Icon">
                  Your Profile
                </div>
              </li>
              <li routerLink="/site/favorite" target="_blank" class="block cursor-pointer rounded-md px-3 py-2 text-sm font-medium text-gray-200 hover:bg-night-500">
                <div class="flex items-center ml-auto">
                  <img src="assets/icons/outline/heart.svg" class="h-5 w-5 mr-2 filter invert" alt="Search Icon">
                  Favorites
                </div>
              </li>
              <li (click)="logout()"
                class="dark:divide-gray-60 block cursor-pointer rounded-md px-3 py-2 text-sm font-medium text-gray-200">
                <div class="flex items-center">
                  <img src="assets/icons/outline/logout-profileMenu.svg" class="h-5 w-5 mr-2 filter invert" alt="Search Icon">
                  <button>Sign out</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

