export const environment = {
  production: true,
  //apiUrl: 'https://18.224.60.75.nip.io/api',
  // apiUrl: 'https://3.149.250.29.nip.io/api',
  //apiUrl: 'https://18.117.233.230.nip.io/api',
  //apiUrl: 'https://3.145.85.130.nip.io/api',

  apiUrl: 'https://18.223.203.43.nip.io/api',
  firebase: {
    apiKey: 'AIzaSyA62dyz2K9Uw4g-1F6yUSgkOfbaOgw9kfM',
    authDomain: 'live-ethio-sms-verification.firebaseapp.com',
    projectId: 'live-ethio-sms-verification',
    storageBucket: 'live-ethio-sms-verification.appspot.com',
    messagingSenderId: '266548051327',
    appId: '1:266548051327:web:d61c5b08bbe1578e326e4a',
    measurementId: 'G-LLYYN4FMQ6',
  },
};
