import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { AuthGuardService } from 'src/app/core/guards/authGuard.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {

  signInForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });
  isLoggedIn = false;
  isLoginFailed = false;
  googleLoginFailed = false;
  errorMessage = '';
  role: any;
  passwordTextType!: boolean;
  submitted = false;
  public redirectUrl!: any;
  checkPhoneVerified = true;
  phone = [''];
  noPhone = false;
  userId: any;

  constructor(private authGuard: AuthGuardService, private loaderService: LoaderService, private router: Router, private authService: AuthService, private storageService: StorageService, private fb: FormBuilder, private userService: UserService) { }

  ngOnInit(): void {
    this.loaderService.hideLoader();
    if (this.storageService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.authService.getCurrentUser().pipe(first()).subscribe(user => {
        this.authService.setLoggedInUserDetails(user);
        this.role = user.role;
      })
    }
  }

  get f() { return this.signInForm.controls; }

  onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.signInForm.invalid) {
      return;
    }
    this.authService.login(this.signInForm.value).subscribe((res: any) => {
      this.isLoginFailed = false;
      // if (res.phone_verified) {
        this.storageService.saveUser(res, res.accessToken);
        this.isLoggedIn = true;
        if (this.storageService.isLoggedIn()) {
          this.authService.getCurrentUser().pipe(first()).subscribe(user => {
            this.authService.setLoggedInUserDetails(user);
            this.role = user.role;
            this.router.navigate(['/']);
          })
        }
        // if (this.authGuard.url) {
        //   this.router.navigateByUrl(this.authGuard.url);
        //   this.authGuard.url = null;
        //   this.router.navigateByUrl("/")
        // }
        
        //else { this.router.navigateByUrl("/") }
      // } else {
      //   this.checkPhoneVerified = false;
      //   if (res.phone_number) {
      //     this.phone = res.phone_number;
      //   } else {
      //     this.noPhone = true
      //   }
      //   this.userId = res.id;
      // }
    },
      err => { this.isLoginFailed = true; }
    );
  }

  reloadPage(): void {
    window.location.reload();
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  verifyPhoneNumber() {
    if (this.noPhone) {
      this.router.navigateByUrl("/auth/phone-verification", { state: { data: this.phone, from: "Google", userId: this.userId } })
    }
    else {
      this.router.navigateByUrl("/auth/phone-verification", { state: { data: this.phone, from: "SignUp", userId: this.userId } })
    }
  }

  signInWithGoogle() {
    this.authService.GoogleAuth().then((a) => {
      const userForm = { email: a?.email };
      this.authService.loginWithGoogle(userForm).subscribe((res: any) => {
        this.isLoginFailed = false;
        if (res.phone_verified) {
          this.storageService.saveUser(res, res.accessToken);
          this.isLoggedIn = true;
          if (this.storageService.isLoggedIn()) {
            this.authService.getCurrentUser().pipe(first()).subscribe(user => {
              this.authService.setLoggedInUserDetails(user);
              this.role = user.role;
              this.router.navigate(['/']);
            })
          }
          if (this.authGuard.url) {
            this.router.navigateByUrl(this.authGuard.url);
            this.authGuard.url = null;
          }
          
          //else { this.router.navigateByUrl("/") }
        } else {
          this.checkPhoneVerified = false;
          if (res.phone_number) {
            this.phone = res.phone_number;
          } else {
            this.noPhone = true
          }
          this.userId = res.id;
        }
      },
        err => { this.isLoginFailed = true; this.googleLoginFailed = true }
      );
    });
  }
}
