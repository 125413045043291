import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  isLoaderShown = new BehaviorSubject<boolean>(false);

  constructor() { }

  public showLoader() {
    // this.isLoaderShown.next(false);
  }

  public hideLoader() {
    // this.isLoaderShown.next(false);
  }

  public show() {
    this.isLoaderShown.next(true);
  }

  public hide() {
    this.isLoaderShown.next(false);
  }

}
