import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { first } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage.service';
import { UserService } from 'src/app/core/services/user.service';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  show: boolean = false
  show1: boolean = false
  show2: boolean = false
  isOpenedList: any;
  user: any;
  isList: number = 0;
  isMenu: boolean = false;
  isSearch: boolean = false;
  oDropdown: boolean = false;
  public isMenuOpen = false;
  oDropdown1: boolean = false;
  public isMenuOpen1 = false;
  oDropdown2: boolean = false;
  public isMenuOpen2 = false;
  oDropdown3: boolean = false;
  public isMenuOpen3 = false;
  isNavigationInProgress = false;
  isSubMenuOpen: boolean = false;

  @Input() public backButton: boolean = true;
  @Input() isNav: boolean | undefined;
  @Output() isNavChange = new EventEmitter<boolean>();

  constructor(private location: Location, private storageService: StorageService, private userService: UserService, private authService: AuthService) { }

  ngOnInit() {
    if (this.storageService.isLoggedIn() && !this.storageService.tokenExpired()) {
      // this.user = this.authService.LoggedInUser;
      // console.log(this.user);
      this.authService.getLoggedInUser().pipe(first()).subscribe(user => {
        // this.authService.userDetails.subscribe((user:any)=>{
        this.user = user;
      });
    }
  }

  mobMenu() {
    this.isMenu = true;
    document.body.style.position = 'fixed';
  }

  openMenu(source: any) {
    if (!this.isNavigationInProgress) {
    this.isOpenedList = source;
    }
  }
  closeMenu() {
    if (!this.isNavigationInProgress) {
    this.isOpenedList = null;
    }
  }
  openMenu1(source: any) {
    if (!this.isNavigationInProgress) {
    this.isOpenedList = source;
    }
  }
  closeMenu1() {
    if (!this.isNavigationInProgress) {
    this.isOpenedList = null;
    }
  }
  openMenu2(source: any) {
    if (!this.isNavigationInProgress) {
    this.isOpenedList = source;
    }
  }
  closeMenu2() {
    if (!this.isNavigationInProgress) {
    this.isOpenedList = -1;
    }
  }
  showMenu() {
    this.show = !this.show
  }
  showMenu1() {
    this.show1 = !this.show1
  }
  showMenu2() {
    this.show2 = !this.show2
  }
  

  isloggedin() {
    if (this.storageService.isLoggedIn() && !this.storageService.tokenExpired()) {
      return true;
    } else
      return false;
  }

  goBack() {
    this.location.back();
  }

  public toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  onMenuItemClick(route: string) {
    this.isNavigationInProgress = true;
    // Perform any custom logic, e.g., navigation
    // Then reset the flag to allow menu interactions again
    this.isNavigationInProgress = false;
  }
  
  logout() {
    this.authService.logout();
  }

  
}
