<!--
  Used in 'property-preview' to showcase each of the property images that were uploaded
    1. Button: next and previous
    2. Image location
-->

<div class="slider">
  <p-carousel 
[value]="slides" 
[numVisible]="1" 
[numScroll]="1" 
[circular]="true" 
[showIndicators]="false">
    <ng-template let-product pTemplate="item">
        <div class="border-1 surface-border border-round">
            <div class="mb-3">
                <div class="relative mx-auto">
                    <img src="{{product.url}}"
                        [alt]="product.title" 
                        class="w-full border-round slide" loading="lazy" />
                    <!-- <p-tag 
                        [value]="product.inventoryStatus" 
                        [severity]="getSeverity(product.inventoryStatus)" 
                        class="absolute" 
                        [ngStyle]="{ 'left.px': 5, 'top.px': 5 }" /> -->
                </div>
            </div>
        </div>
    </ng-template>
</p-carousel>
  <!-- <div>
    <div (click)="goToPrevious()" class="leftArrow h-full flex items-center justify-center pr-4"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="4" stroke="currentColor" class="w-6 h-6 svg-shadow">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
    </svg>
    </div>
    <div (click)="goToNext()" class="rightArrow h-full flex items-center justify-center pl-4"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="4" stroke="currentColor" class="w-6 h-6 svg-shadow">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
    </svg>
    </div>
  </div> -->
  <!-- <a [routerLink]="['/site/property-details/' + id]" target="_blank" (touchstart)="onTouchStart($event)" -->
  <!-- <a >
    <div class="slide" loading="lazy" [ngStyle]="{ 'background-image': getCurrentSlideUrl() }"></div>
  </a> -->
</div>