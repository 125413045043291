import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  public url: any;

  constructor(private storageService: StorageService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.url = state.url;
    return this.checkLogin(this.url);
  }

  checkLogin(url: string): boolean {
    if (this.storageService.isLoggedIn() && !this.storageService.tokenExpired()) {
      return true;
    }
    else {
      this.storageService.clean();
      this.router.navigateByUrl('/auth/sign-in');
      return false;
    }
  }

}
