import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Location } from '../models/property.model';
import { Observable, throwError, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
      return throwError('A client-side error occurred. Please check your internet connection and try again.');
    } else {
      
      if (error.status === 0) {
        return throwError('Unable to connect to the server. Please check your internet connection.');
      } else {
        return throwError('An unexpected server-side error occurred.');
      }
    }
  }

  getAll() {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Location[]>(`${environment.apiUrl}/locations`).pipe(
        catchError(this.handleError)
    )};
  }

  get(id: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Location>(`${environment.apiUrl}/locations/${id}`).pipe(
        catchError(this.handleError)
    )};
  }

  create(data: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post(`${environment.apiUrl}/locations`, data).pipe(
        catchError(this.handleError)
    )};
  }

  update(id: any, data: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.put(`${environment.apiUrl}/locations/${id}`, data).pipe(
        catchError(this.handleError)
    )};
  }

  delete(id: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.delete(`${environment.apiUrl}/locations/${id}`).pipe(
        catchError(this.handleError)
    )};
  }
}
