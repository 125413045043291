import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Type } from '../models/property.model';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { Observable, throwError, catchError } from 'rxjs';
import { Subject } from 'rxjs';
const typeNotifier = new Subject<void>();

@Injectable({
  providedIn: 'root'
})
export class TypeService {

  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
      return throwError('A client-side error occurred. Please check your internet connection and try again.');
    } else {
      
      if (error.status === 0) {
        return throwError('Unable to connect to the server. Please check your internet connection.');
      } else {
        return throwError('An unexpected server-side error occurred.');
      }
    }
  }

  @Cacheable({ cacheBusterObserver: typeNotifier })
  getAll() {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Type[]>(`${environment.apiUrl}/types`).pipe(
        catchError(this.handleError)
    )};
  }

  @Cacheable({ cacheBusterObserver: typeNotifier })
  get(id: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Type>(`${environment.apiUrl}/types/${id}`).pipe(
        catchError(this.handleError)
    )};
  }

  @CacheBuster({ cacheBusterNotifier: typeNotifier })
  create(data: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post(`${environment.apiUrl}/types`, data).pipe(
        catchError(this.handleError)
    )};
  }

  @CacheBuster({ cacheBusterNotifier: typeNotifier })
  update(id: any, data: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.put(`${environment.apiUrl}/types/${id}`, data).pipe(
        catchError(this.handleError)
    )};
  }

  @CacheBuster({ cacheBusterNotifier: typeNotifier })
  delete(id: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.delete(`${environment.apiUrl}/types/${id}`).pipe(
        catchError(this.handleError)
    )};
  }
}
