import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, finalize, tap } from 'rxjs';
import { Router } from '@angular/router';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, public router: Router, private loaderService: LoaderService ) { }

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   if (this.auth.getToken()) {
  //     request = request.clone({
  //       setHeaders: {
  //         "x-access-token": this.auth.getToken()!
  //       }
  //     });
  //   }
  //   else {
  //     this.router.navigateByUrl("auth")
  //   }
  //   return next.handle(request);
  // }
  private totalRequests: number = 0;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.totalRequests++;
    if (request.body && request.body.source && request.body.source == 'PopUp-Website'){

    }else{
      this.loaderService.show();
    }
    
    if (this.auth.getToken()) {
      request = request.clone({
        setHeaders: {
          "x-access-token": this.auth.getToken()!
        }
      });
    }

    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests == 0) {
          this.loaderService.hide();
        }
      })
    ).pipe(tap(() => { },
    (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status == 404){
          this.router.navigateByUrl('/');
        }else if (err.status !== 401) {
          return;
        }
        this.router.navigateByUrl('/');
      }
    }));

    // return next.handle(request).pipe(tap(() => { },
    //   (err: any) => {
    //     if (err instanceof HttpErrorResponse) {
    //       if (err.status !== 401) {
    //         return;
    //       }
    //       this.router.navigateByUrl('/');
    //     }
    //   }));
  }
}

export const tokenInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
];
