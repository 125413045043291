import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponsiveHelperComponent } from './components/responsive-helper/responsive-helper.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
  declarations: [ResponsiveHelperComponent, LoaderComponent, ClickOutsideDirective],
  imports: [CommonModule],
  exports: [ResponsiveHelperComponent, LoaderComponent, ClickOutsideDirective],
})
export class SharedModule {}
