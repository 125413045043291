<div class="flex flex-col w-full h-full items-center justify-center bg-gray-100 z-[100] fixed">
  <img src="../../../../assets/icons/le-logo.png" class="animate-pulse h-20 w-20"/>
  <h1 class="pl-4 text-gray-400 font-medium text-2xl m-4 animate-pulse">Loading...</h1>
</div>

<!--<div class="flex flex-col w-full h-full items-center justify-center bg-gray-100 z-[100] fixed">
  <img src="../../../../assets/icons/le-logo.png" class="animate-pulse h-20 w-20" alt="Logo"/>
  <p class="pl-4 text-gray-600 font-medium text-2xl sm:text-lg m-4 animate-pulse">Website Currently Under Maintenance</p>
  <p class="text-gray-600 font-medium text-lg sm:text-xs mb-4">Contact us via call, WhatsApp or Telegram at: <a href="tel:+251974299472">+251974299472</a></p>
</div>-->
