<!-- <head> -->
  <!-- <meta charset="UTF-8"> -->
  <!-- <meta name="viewport" content="width=device-width, initial-scale=1.0"> -->
  <!-- <title>Live Ethio: Properties for Rent and Sale in Addis Ababa Ethiopia </title> -->
  <!-- <meta name="description"
    content="Buy, sell, and rent properties in Addis Ababa, Ethiopia with Live Ethio Real Estate. Browse houses, apartments, condominiums & more. "> -->
  <!-- <meta name="keywords"
    content="Real Estate, Ethiopia, Addis Ababa, Live Ethio, Rent, Sale, Buy, Sell, Apartment, House, Property, condominium"> -->
  <!--<meta property="og:site_name" content="Live Ethio">
  <meta property="og:type" content="website">
  <meta property="og:image" content="https://le-assets.s3.us-east-2.amazonaws.com/bg/le-logo.png">
  <meta property="og:title" content="Properties for Rent & Sale in Addis Ababa Ethiopia">
  <meta property="og:url" content="https://livingethio.com">
  <meta property="og:description" content="Buy, sell, and rent properties with Live Ethio, the leading real estate marketplace in Addis Ababa, Ethiopia. Browse houses, apartments, condominiums & more.">-->
  <!-- <link rel="preload" href="https://le-assets.s3.us-east-2.amazonaws.com/bg/bg-hero-l.webp" as="image" /> -->
<!-- </head> -->

<body [ngClass]="navOpen? 'fixed w-screen min-w-[385px]': 'relative'">
  <app-header [(isNav)]="navOpen" [backButton]="false"></app-header>
  <div>
    <div class="w-full min-h-[400px] lazyload bg-center bg-[url('https://le-assets.s3.us-east-2.amazonaws.com/bg/bg-hero-l.webp')]
    xl:h-[75vh] mx-auto flex flex-col items-center pt-12 sm:pt-8 pb-24 sm:pb-32 md:pb-48 lg:pb-56 xl:pb-64"
      style="background-size: cover;">
      <div class="w-11/12 sm:w-full mb-5 mt-16 sm:mt-10 sm:mb-10">
        <h1
          class="sm:text-3xl sm:font-semibold md:text-4xl lg:text-5xl xl:text-6xl text-center text-white font-bold leading-tight heading_word_spacing">
          Rent. Sell. Buy. Manage.</h1>
        <h2 class="font-normal text-white text-center sm:text-xl text-2xl mt-7 sm:mt-4">The Marketplace That Has It All.
        </h2>
      </div>
    </div>
    <form [formGroup]="searchPropertyForm" (ngSubmit)="onSearch()">
      <div class="mx-auto flex justify-center xl:-mt-44 lg:-mt-44 md:-mt-32 sm:-mt-40">
        <div class="relative flex-col flex items-center sm:w-11/12 md:w-11/12 xl:w-5/6 rounded shadow-md">
          <div class="xl:mx-0 xl:w-1/2 md:w-2/3 sm:w-5/6 h-12 bg-white rounded-t">
            <div class="xl:w-full xl:mx-0 h-12 grid grid-cols-3">
              <div (click)="tabActive(1, null)"
                [ngClass]="active_status == 1 ? 'bg-primary-500 text-white cursor-pointer' : 'text-gray-600 cursor-pointer border-r border-gray-100'"
                class="text-sm flex justify-center pt-3 rounded-tl font-normal">
                <span>All Status</span>
              </div>
              <div (click)="tabActive(2, 'For Rent')"
                [ngClass]="active_status == 2 ? 'bg-primary-500 text-white cursor-pointer' : 'text-gray-600 cursor-pointer border-r border-l border-gray-100'"
                class="text-sm flex justify-center pt-3 font-normal">
                <span>For Rent</span>
              </div>
              <div (click)="tabActive(3, 'For Sale')"
                [ngClass]="active_status == 3 ? 'bg-primary-500 text-white cursor-pointer' : 'text-gray-600 cursor-pointer border-l border-gray-100'"
                class="text-sm flex justify-center pt-3 rounded-tr font-normal">
                <span>For Sale</span>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between w-full">
            <div class="flex flex-col lg:flex-row w-full items-start lg:items-center rounded bg-white shadow">
              <div class="w-full py-6">
                <div class="gap-3 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 grid-cols-4 px-7">
                  <div class="border-gray-300" [hidden]="this.active_tab == 'Land'">
                    <p class="text-base font-normal leading-none text-gray-800">
                      Property Type
                    </p>
                    <div class="mt-4">
                      <select formControlName="type_id" data-te-select-init multiple data-te-select-size="lg"
                        data-te-select-placeholder="Select"
                        data-te-class-select-arrow-lg="!w-4 !h-4 !top-[15px] text-gray-600"
                        data-te-class-select-input="!py-3 !leading-none text-sm cursor-pointer">
                        <option *ngFor="let type of types" [value]="type.id">
                          {{ type.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="border-gray-300" [hidden]="this.active_tab == 'Land'">
                    <p class="text-base font-normal leading-none text-gray-800">
                      Bedrooms
                    </p>
                    <div class="mt-4">
                      <select name="bedroom" formControlName="bedroom" data-te-select-init multiple data-te-select-size="lg"
                        data-te-select-placeholder="Select"
                        data-te-class-select-arrow-lg="!w-4 !h-4 !top-[15px] text-gray-600"
                        data-te-class-select-input="!py-3 !leading-none text-sm cursor-pointer">
                        <option *ngFor="let bed of bedrooms" [value]="bed">
                          {{ bed }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="border-gray-300">
                    <p class="text-base font-normal leading-none text-gray-800">
                      Max. Price
                    </p>
                    <select name="maxprice" formControlName="max_price" (change)="changeMe()" id="budget-sel"
                      class="cursor-pointer w-full mt-4 p-3 border text-gray-400 focus:text-black border-gray-300 rounded text-sm focus:bg-gray-50">
                      <option selected disabled value="null">Select</option>
                      <option *ngFor="let birr of budget" [value]="birr">
                        Br. {{toLocale(birr)}}</option>
                    </select>
                  </div>
                  <div class="border-gray-300" [hidden]="this.active_tab !== 'Land'">
                    <p class="text-base font-normal leading-none text-gray-800">
                      Location
                    </p>
                    <div class="mt-4">
                      <select name="location" formControlName="area" data-te-select-init multiple data-te-select-size="lg"
                        data-te-select-placeholder="Select"
                        data-te-class-select-arrow-lg="!w-4 !h-4 !top-[15px] text-gray-600"
                        data-te-class-select-input="!py-3 !leading-none text-sm cursor-pointer">
                        <option *ngFor="let location of locations" [value]="location.id">
                          {{ location?.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="flex justify-center sm:mt-2 space-x-2 sm:space-x-1">
                    <!--<div [ngClass]="{'filter-bg': isFilterClicked}"  class="h-[45.33px] w-1/2 sm:w-2/3 md:mt-2 sm:mt-0 flex-none rounded-md px-4 py-2.5 text-sm">
                      <app-search [(isSearch)]="smSearch" (onSearchClick)="filterSearch($event)"></app-search>
                    </div>-->
                    <button type="submit"
                      class="h-[45.33px] w-1/2 md:mt-8 flex-none rounded-md bg-primary-500 px-4 py-2.5 text-sm text-white hover:bg-primary-600">
                      More Filters
                    </button>
                    <button type="submit"
                      class="h-[45.33px] w-1/2 md:mt-8 flex-none rounded-md bg-primary-500 px-4 py-2.5 text-sm text-white hover:bg-primary-600">
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- 
    Featured Listings 
  -->
  <div class="lg:px-20 md:px-6 px-4 pt-10 pb-8 bg-gray-50">
    <div class="flex flex-col items-center justify-center">
      <!-- <h2 class="lg:text-3xl text-xl font-bold text-center text-gray-700">Featured Listings</h2> -->
      <h2 class="text-4xl sm:text-2xl text-center font-bold leading-none tracking-tighter text-neutral-600">Featured
        Listings: Verified & Vetted</h2>
      <!-- <p class="text-base leading-6 mt-4 text-center text-gray-500 2xl:w-2/5 sm:w-96 subheading_space">Verified & Vetted Listings</p> -->
    </div>
  </div>
  <div class="bg-gray-50" loading="lazy">
    <div class="mx-8 xl:mx-16 sm:mx-4 py-2 flex justify-center">
      <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-6 items-center w-full">
        <div *ngFor="let property of featuredProperties">
          <app-property-preview [property]="property"></app-property-preview>
        </div>
      </div>
    </div>
    <div class="flex justify-center pb-8">
      <button (click)="seeMoreFeatured()"
        class="h-12 mt-4 flex-none text-primary-900 rounded-md bg-transparent border-primary-900 border px-10 py-2.5 text-sm hover:bg-primary-900 hover:text-white">
        See All Properties
      </button>
    </div>
  </div>

  <!--
    Property Type Counter 
  -->
  <div class="lg:px-16 md:px-6 px-4 md:pt-12 py-8" loading="lazy">
    <h2 class="lg:text-3xl text-2xl font-bold leading-none tracking-tighter text-neutral-600 lg:mb-10">What Are You
      Looking For?</h2>
    <div class="flex justify-center">
      <div
        class="grid sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-3 md:gap-6 lg:gap-6 lg:mt-12 md:mt-8 mt-4 items-center w-full">
        <a routerLink="/site/property/apartment-for-rent" target="_blank">
          <div class="relative flex flex-col rounded-md cursor-pointer">
            <img alt="Apartment For Rent" title="Apartment For Rent" src="https://d36je99kkzzf8x.cloudfront.net/bg/0_23-0.Apartment-vs-House.aa5b55c5.jpg"
              class="w-full rounded-md h-64 sm:h-32 object-cover" loading="lazy" />

            <div class="opacity-90 bg-gradient-to-t from-black absolute top-0 left-0 h-full w-full rounded-md"></div>
            <div
              class="opacity-20 bg-gradient-to-t from-gray-500 to-gray-500 hover:opacity-0 absolute top-0 left-0 h-1/2 w-full rounded-md">
            </div>
            <div class="absolute md:m-6 sm:m-3 bottom-0 z-30">
              <p class="text-sm leading-none text-white">{{numApartmentForRent - 1}} Properties</p>
              <h2 class="w-full text-xl sm:text-sm font-semibold leading-8 mt-2 text-white">Apartment For Rent</h2>
              <p class="sm:hidden mt-4 text-base font-medium cursor-pointer leading-4 underline text-white">Discover</p>
            </div>
          </div>
        </a>
        <a routerLink="/site/property/apartment-for-sale" target="_blank">
          <div class="relative flex flex-col rounded-md lg:-mt-32 cursor-pointer">
            <img alt="Apartment For Sale" title="Apartment For Sale" src="https://d36je99kkzzf8x.cloudfront.net/bg/nordic+interior+home_1198457.jpg"
              class="w-full rounded-md h-64 sm:h-32 object-cover" loading="lazy" />
            <div class="opacity-90 bg-gradient-to-t from-black absolute top-0 left-0 h-full w-full rounded-md"></div>
            <div
              class="opacity-20 bg-gradient-to-t from-gray-500 to-gray-500 hover:opacity-0 absolute top-0 left-0 h-full w-full rounded-md">
            </div>
            <div class="absolute md:m-6 sm:m-3 bottom-0 z-30">
              <p class="text-sm leading-none text-white">{{numApartmentForSale - 1}} Properties</p>
              <h2 class="w-full text-xl sm:text-sm font-semibold leading-8 mt-2 text-white">Apartment For Sale</h2>
              <p class="sm:hidden mt-4 text-base font-medium cursor-pointer leading-4 underline text-white">Discover</p>
            </div>
          </div>
        </a>
        <a routerLink="/site/property/house-for-rent" target="_blank">
          <div class="relative flex flex-col rounded-md cursor-pointer">
            <img alt="House For Rent" title="House For Rent" src="https://le-assets.s3.us-east-2.amazonaws.com/bg/beautiful-shot-modern-house.jpeg"
              class="w-full rounded-md h-64 sm:h-32 object-cover" loading="lazy" />
            <div class="opacity-90 bg-gradient-to-t from-black absolute top-0 left-0 h-full w-full rounded-md"></div>
            <div
              class="opacity-20 bg-gradient-to-t from-gray-500 to-gray-500 hover:opacity-0 absolute top-0 left-0 h-1/2 w-full rounded-md">
            </div>
            <div class="absolute md:m-6 sm:m-3 bottom-0 z-30">
              <p class="text-sm leading-none text-white">{{numHouseForRent - 1 }} Properties</p>
              <h2 class="w-full text-xl sm:text-sm font-semibold leading-8 mt-2 text-white">House For Rent</h2>
              <p class="sm:hidden mt-4 text-base font-medium cursor-pointer leading-4 underline text-white">Discover</p>
            </div>
          </div>
        </a>
        <a routerLink="/site/property/house-for-sale" target="_blank">
          <div class="relative flex flex-col rounded-md cursor-pointer">
            <img alt="House For Sale" title="House For Sale" src="https://d36je99kkzzf8x.cloudfront.net/bg/RentalHome_opt.jpeg"
              class="w-full rounded-md h-64 sm:h-32 object-cover" loading="lazy" />
            <div class="opacity-90 bg-gradient-to-t from-black absolute top-0 left-0 h-full w-full rounded-md"></div>
            <div
              class="opacity-20 bg-gradient-to-t from-gray-500 to-gray-500 hover:opacity-0 absolute top-0 left-0 h-1/2 w-full rounded-md">
            </div>
            <div class="absolute md:m-6 sm:m-3 bottom-0 z-30">
              <p class="text-sm leading-none text-white">{{numHouseForSale}} Properties</p>
              <h2 class="w-full text-xl sm:text-sm font-semibold leading-8 mt-2 text-white">House For Sale</h2>
              <p class="sm:hidden mt-4 text-base font-medium cursor-pointer leading-4 underline text-white">Discover</p>
            </div>
          </div>
        </a>
        <a routerLink="/site/property/type/land" target="_blank">
          <div class="relative flex flex-col rounded-md lg:-mt-32 cursor-pointer">
            <img alt="Land For Sale" title="Land For Sale" src="https://d36je99kkzzf8x.cloudfront.net/bg/land-for-sale-rural-landscape.jpg"
              class="w-full rounded-md h-64 sm:h-32 object-cover" loading="lazy" />
            <div class="opacity-90 bg-gradient-to-t from-black absolute top-0 left-0 h-full w-full rounded-md"></div>
            <div
              class="opacity-20 bg-gradient-to-t from-gray-500 to-gray-500 hover:opacity-0 absolute top-0 left-0 h-1/2 w-full rounded-md">
            </div>
            <div class="absolute md:m-6 sm:m-3 bottom-0 z-30">
              <p class="text-sm leading-none text-white">{{numLand}} Properties</p>
              <h2 class="w-full text-xl sm:text-sm font-semibold leading-8 mt-2 text-white">Land For Sale</h2>
              <p class="sm:hidden mt-4 text-base font-medium cursor-pointer leading-4 underline text-white">Discover</p>
            </div>
          </div>
        </a>
        <a routerLink="/site/property/type/condo" target="_blank">
          <div class="relative flex flex-col rounded-md cursor-pointer">
            <img alt="Condominium For Rent & Sale" title="Condominium For Rent & Sale"
              src="https://d36je99kkzzf8x.cloudfront.net/bg/studio-apartment-ideas-room-divider-ideas-tranebergsva-gen-stockholm-living-room-industry-fantastic-frank-popular-copy-1589570199.jpg"
              class="w-full rounded-md h-64 sm:h-32 object-cover" loading="lazy" />
            <div class="opacity-90 bg-gradient-to-t from-black absolute top-0 left-0 h-full w-full rounded-md"></div>
            <div
              class="opacity-20 bg-gradient-to-t from-gray-500 to-gray-500 hover:opacity-0 absolute top-0 left-0 h-1/2 w-full rounded-md">
            </div>
            <div class="absolute md:m-6 sm:m-3 bottom-0 z-30">
              <p class="text-sm leading-none text-white">{{numCondo}} Properties</p>
              <h2 class="w-full text-xl sm:text-sm font-semibold leading-8 mt-2 text-white">Condominium For Rent & Sale
              </h2>
              <p class="sm:hidden mt-4 text-base font-medium cursor-pointer leading-4 underline text-white">Discover</p>
            </div>
          </div>
        </a>
        <a routerLink="/site/property/type/new-development" target="_blank">
          <div class="relative flex flex-col rounded-md cursor-pointer">
            <img alt="New Development" title="New Development" src="https://d36je99kkzzf8x.cloudfront.net/bg/shutterstock_765579673.jpg"
              class="w-full rounded-md h-64 sm:h-32 object-cover" loading="lazy" />
            <div class="opacity-90 bg-gradient-to-t from-black absolute top-0 left-0 h-full w-full rounded-md"></div>
            <div
              class="opacity-20 bg-gradient-to-t from-gray-500 to-gray-500 hover:opacity-0 absolute top-0 left-0 h-1/2 w-full rounded-md">
            </div>
            <div class="absolute md:m-6 sm:m-3 bottom-0 z-30">
              <p class="text-sm leading-none text-white">{{numDevelopment}} Properties</p>
              <h2 class="w-full text-xl sm:text-sm font-semibold leading-8 mt-2 text-white">New Development</h2>
              <p class="sm:hidden mt-4 text-base font-medium cursor-pointer leading-4 underline text-white">Discover</p>
            </div>
          </div>
        </a>
        <a routerLink="/site/property/label/furnished" target="_blank">
          <div class="relative flex flex-col rounded-md lg:-mt-32 cursor-pointer">
            <img alt="Furnished" title="Furnished" src="https://d36je99kkzzf8x.cloudfront.net/bg/Blog_FurnishedApartmentDemand_hero.jpg"
              class="w-full rounded-md h-64 sm:h-32 object-cover" loading="lazy" />
            <div class="opacity-90 bg-gradient-to-t from-black absolute top-0 left-0 h-full w-full rounded-md"></div>
            <div
              class="opacity-20 bg-gradient-to-t from-gray-500 to-gray-500 hover:opacity-0 absolute top-0 left-0 h-1/2 w-full rounded-md">
            </div>
            <div class="absolute md:m-6 sm:m-3 bottom-0 z-30">
              <p class="text-sm leading-none text-white">{{numFurnished}} Properties</p>
              <h2 class="w-full text-xl sm:text-sm font-semibold leading-8 mt-2 text-white">Furnished</h2>
              <p class="sm:hidden mt-4 text-base font-medium cursor-pointer leading-4 underline text-white">Discover</p>
            </div>
          </div>
        </a>
        <a routerLink="/site/property/label/unfurnished" target="_blank">
          <div class="relative flex flex-col rounded-md cursor-pointer">
            <img alt="Unfurnished" title="Unfurnished" src="https://d36je99kkzzf8x.cloudfront.net/bg/empty_home_shutterstock_1937203537.jpg"
              class="w-full rounded-md h-64 sm:h-32 object-cover" loading="lazy" />
            <div class="opacity-90 bg-gradient-to-t from-black absolute top-0 left-0 h-full w-full rounded-md"></div>
            <div
              class="opacity-20 bg-gradient-to-t from-gray-500 to-gray-500 hover:opacity-0 absolute top-0 left-0 h-1/2 w-full rounded-md">
            </div>
            <div class="absolute md:m-6 sm:m-3 bottom-0 z-30">
              <p class="text-sm leading-none text-white">{{numUnfurnished}} Properties</p>
              <h2 class="w-full text-xl sm:text-sm font-semibold leading-8 mt-2 text-white">Unfurnished</h2>
              <p class="sm:hidden mt-4 text-base font-medium cursor-pointer leading-4 underline text-white">Discover</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>

  <!-- 
    Inquiry Form 
  -->
  <div class="grid grid-cols-2 sm:grid-cols-1 items-center bg-primary-900">
    <div class="py-16 px-10 grid grid-cols-2 sm:grid-cols-1 gap-8 justify-center items-center">
      <div>
        <h2 class="lg:text-3xl text-xl font-bold leading-none tracking-tighter text-white">Do You Own A Property In
          Ethiopia?</h2>
        <hr class="w-16 mt-12 mb-4">
        <p class="lg:text-3xl text-xl font-medium text-white">01.</p>
        <!--<h2 class="lg:text-3xl text-xl font-normal text-white mt-4">Let's Help You Rent Or Sell Your Property</h2>-->
        <h2 class="lg:text-3xl text-xl font-normal text-white mt-4">Rent or Sell with Ease.</h2>
        <p class="text-sm leading-6 mt-4 text-gray-300">
          We simplify renting and selling by providing search, negotiation, and paperwork support for renters, along
          with valuation, marketing, and closing assistance for sellers.
        </p>
        <hr class="w-16 mt-10 mb-4">
      </div>
      <div>
        <p class="lg:text-3xl text-xl font-medium text-white">02.</p>
        <h2 class="lg:text-3xl text-xl font-normal text-white mt-4">Need a Property Manager?</h2>
        <p class="text-sm leading-6 mt-4 text-gray-300">
          End your search here. We handle everything in rental property management—from paperwork to tenant background
          checks, delivering the best deals.</p>
        <hr class="w-16 mt-10 mb-4">
        <p class="lg:text-3xl text-xl font-medium text-white">03.</p>
        <h2 class="lg:text-3xl text-xl font-normal text-white mt-4">Renovation & Consultancy</h2>
        <p class="text-sm leading-6 mt-4 text-gray-300">
          Boost your property's visibility with our custom marketing services and thorough home renovation, ensuring a
          polished and well-marketed appeal.</p>
        <hr class="w-16 mt-10 mb-4">
      </div>
    </div>
    <div class="xl:px-8 mx-3 bg-gray-50 my-4 sm:px-6 md:px-10">
      <form class="py-7" [formGroup]="inquiryForm" (ngSubmit)="onInquiry()" (submit)="sendEmail($event)">
        <div>
          <p class="text-base font-bold leading-none text-gray-1000">
            Inquiry Type*
          </p>
          <select formControlName="inquiry_type" [ngClass]="{ 'is-invalid': submitted && f.inquiry_type.errors }"
            class="w-full mt-4 p-3 border border-gray-300 rounded text-sm focus:bg-gray-50">
            <option selected disabled value="null">Select</option>
            <option value="Rent">Rent</option>
            <option value="Sell">Sell</option>
            <option value="Buy">Buy</option>
            <option value="Evaluation">Evaluation</option>
            <option value="Mortgage">Mortgage</option>
            <option value="General">General Questions</option>
          </select>
          <div *ngIf="submitted && f.inquiry_type.errors" class="mt-2 text-sm text-red-500 italic">
            <div *ngIf="f.inquiry_type.errors.required">Inquiry type is required</div>
          </div>
        </div>
        <div>
          <input placeholder="Full Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
            class="placeholder-gray-800 w-full p-3 mt-4 border border-gray-300 rounded outline-none focus:bg-gray-50" />
          <div *ngIf="submitted && f.name.errors" class="mt-2 text-sm text-red-500 italic">
            <div *ngIf="f.name.errors.required">Full Name is required</div>
          </div>
        </div>
        <div formArrayName="phone" *ngFor="let phone of phone.controls; let i = index">
          <input placeholder="Phone Number" [formControlName]="i"
            [ngClass]="{ 'is-invalid': submitted && phone.errors }"
            class="placeholder-gray-800 w-full p-3 mt-4 border border-gray-300 rounded outline-none focus:bg-gray-50" />
          <div *ngIf="submitted && phone.errors" class="mt-2 text-sm text-red-500 italic">
            <div *ngIf="phone.errors.required">Phone Number is required</div>
            <div *ngIf="phone.errors.minlength">Phone Number must be at least 10 digits</div>
          </div>
        </div>
        <div class="mt-4">
          <textarea id="about" name="about" formControlName="note" placeholder="Any Additional Request"
            class="w-full bg-white border border-gray-300 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-primary-500 resize-none placeholder-gray-800"
            rows="3"></textarea>
        </div>
        <div>
          <p class="text-base font-medium leading-none text-gray-800 mt-4">
            GDPR Agreement
          </p>
          <div class="flex items-center mt-4">
            <input formControlName="consent" type="checkbox" [ngClass]="{ 'is-invalid': submitted && f.consent.errors }"
              class="flex-shrink-0 bg-white cursor-pointer w-5 h-5" />
            <p class="ml-3 text-sm leading-4 text-gray-800">I consent to having this
              website store my submitted information
            </p>
          </div>
          <div *ngIf="submitted && f.consent.errors" class="mt-2 text-sm text-red-500 italic">
            This is required
          </div>
        </div>
        <button type="submit"
          class="w-full mt-5 rounded-md bg-primary-500 px-4 py-2.5 text-md font-semibold text-white hover:bg-primary-600">
          Submit
        </button>
        <div *ngIf="finalSubmit" role="alert"
          class="mt-4 mb-0 mx-auto w-full justify-between bg-white dark:bg-gray-800 shadow-lg rounded flex flex-row"
          id="notification">
          <div class="px-6 flex items-center justify-center bg-primary-900 rounded-tl rounded-bl text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40" fill="currentColor">
              <path class="heroicon-ui"
                d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z" />
            </svg>
          </div>
          <div class="xl:-ml-4 pl-4 xl:pl-1 w-3/5 pt-4 pb-4">
            <p class="text-lg text-gray-800 dark:text-gray-100 font-semibold pb-1">Inquiry Sent!</p>
          </div>
          <div
            class="flex flex-col justify-center border-l dark:border-gray-700 items-center border-gray-300 w-1/6 pl-4">
            <div class="pt-4 pb-4 flex justify-center w-full cursor-pointer" (click)="finalSubmit = false">
              <span class="sm:text-sm text-xs text-gray-600 dark:text-gray-400 cursor-pointer">Dismiss</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- 
    New Development 
  -->
  <div class="lg:px-16 md:px-6 px-4 md:py-12 py-8 bg-gray-100">
    <div class="items-center md:w-3/5 text-center" style="margin: auto;">
      <div>
        <h2 class="lg:text-3xl text-xl font-bold text-gray-700 mb-5">New Developments In Addis</h2>
        <p class="text-sm leading-6 mt-4 text-gray-500">Live Ethio collaborates with leading development firms to assist
          you in finding the perfect property. Our dedicated team
          offers expert property advice, helps secure the ideal financing options, and ensures a seamless buying
          experience. Whether you're seeking an investment opportunity or
          a place to call home we're here to help. With a diverse portfolio of new development projects, we invite you
          to explore our latest listings and take the first step
          towards securing your dream property.
        </p>
        <div class="flex pb-6 mt-3 justify-center">
          <button [routerLink]="['/site/property/type/new-development']"
            class="h-12 mt-4 flex-none text-primary-900 rounded-md bg-transparent border-primary-900 border px-10 py-2.5 text-sm hover:bg-primary-900 hover:text-white">
            See All Properties
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 
    Real Estate News 
  -->
  <div class="lg:px-20 md:px-6 px-4 py-12 sm:py-8 bg-gray-50" loading="lazy">
    <div class="flex flex-col items-center justify-center">
      <h2 class="lg:text-4xl text-3xl font-bold leading-none tracking-tighter text-neutral-600 text-center ">Ethiopian Real Estate News</h2>
    </div>
  </div>
  <div class="bg-gray-50" loading="lazy">
    <div class="flex items-center justify-center w-full h-full sm:px-1 md:px-4">
      <div class="w-full relative flex items-center justify-center px-12 sm:px-3">
        <div class="w-full h-full py-10">
          <div id="slider"
            class="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
            <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-4 gap-y-6 items-center w-full">
              <div *ngFor="let post of posts.slice(0,4)">
                <div class="w-full mb-0 rounded-md shadow-md">
                  <div>
                    <div class="relative overflow-hidden rounded-xl cursor-pointer" [routerLink]="['/site/blog', post?.slug]">
                      <!-- <div *ngIf="!imageLoaded"
                        class="spinner-container absolute top-0 left-0 w-full h-full flex items-center justify-center">
                        <div class="spinner"></div>
                      </div> -->
                      <img  src="{{ post?.images?.[0]?.url }}"
                        class="w-full h-[300px] md:h-[200px] sm:h-[200px] rounded-t-md object-cover post-image transition duration-500 group-hover:scale-105"
                        loading="lazy" alt="{{post?.title}}" title="{{post?.title}}" (load)="imageLoaded = true" />
                    </div>
                    <!-- <div
                      class=" via-transparent absolute top-0 left-0 h-[300px] md:h-[200px] sm:h-[200px] w-full rounded-t-md">
                    </div> -->
                  </div>
                  <div class="bg-white rounded-b-md">
                    <div class="p-4 md:h-45 flex flex-col justify-between">
                      <div>
                        <div class="flex items-center mt-2">
                          <h2 class="text-lg font-semibold cursor-pointer" [routerLink]="['/site/blog', post?.slug]">{{ post?.title }}</h2>
                        </div>
                        <p class="text-gray-600 text-sm mt-2 " [innerHTML]="post?.content.substring(0, 75) + '...'"></p>
                      </div>
                      <div class="flex items-center mt-2">
                        <a [routerLink]="['/site/blog', post?.slug]" class="text-primary-900 font-semibold " data-wpel-link="internal">Read
                          more</a>
                      </div>
                    </div>
                    <div
                      class="px-5 lg:px-5 md:px-10 py-3 lg:py-3 flex flex-row items-center justify-between border-t border-gray-300 mt-2 ">
                      <div class="flex items-center cursor-pointer">
                        <div class="flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="gray" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                          </svg>
                          <p class="ml-3 text-xs text-gray-600">{{post?.author?.username}}</p>
                        </div>
                      </div>
                      <div class="flex items-center">
                        <div class="flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="gray" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                          </svg>
                          <p class="text-xs ml-3 text-gray-500">{{ post?.createdAt | date: 'd MMMM y' }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center pb-8">
      <button routerLink="/site/blog"
        class="h-12 mt-4 flex-none text-primary-900 rounded-md bg-transparent border-primary-900 border px-10 py-2.5 text-sm hover:bg-primary-900 hover:text-white">
        Read More
      </button>
    </div>
  </div>

  <!-- 
    Youtube 
  -->
  <!-- <div class="lg:px-20 md:px-6 px-4 py-12" loading="lazy">
    <div class="flex flex-col items-center justify-center">
      <h2 class="lg:text-3xl text-xl font-bold text-center text-gray-700">Follow Us On YouTube</h2>
      <p class="text-base leading-6 mt-4 text-center text-gray-500">We post home tours, home price updates, and more!
        Check out our recent home tour video.</p>
    </div>
  </div>
  <div class="grid grid-cols-2 sm:grid-cols-1 items-center pb-8 gap-y-8" loading="lazy">
    <div class="flex flex-col items-center gap-5 h-80">
      <h2 class="lg:text-xl text-lg font-medium text-center text-gray-700">Popular Home Tour Video</h2>
      <div class="w-5/6 h-full rounded-md" #youtubeContainer1></div>
    </div>
    <div class="flex flex-col items-center gap-5 h-80">
      <h2 class="lg:text-xl text-lg font-medium text-center text-gray-700">Weekly Mini Doc Series</h2>
      <div class="w-5/6 h-full rounded-md" #youtubeContainer2></div>
    </div>
  </div>
  <div class="flex justify-center pb-8" loading="lazy">
    <a href="https://www.youtube.com/channel/UCKMzwMiRfg8F1gs5LSlV_yg?sub_confirmation=1?sub_confirmation=1">
      <button class="bg-red-600 py-4 px-7 text-md font-medium text-white rounded-md">Subscribe to our YouTube
        channel</button>
    </a>
  </div> -->
  <app-site-footer loading="lazy"></app-site-footer>
  <app-responsive-helper></app-responsive-helper>
  <!-- <div id="modal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center hidden z-50"> -->
  <!-- <div id="modal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div class="bg-white p-6 rounded-lg shadow-xl w-1/3">
        <h2 class="text-xl font-bold mb-4">This is a Popup</h2>
        <p>This is some text within the popup. You can add any content you like here.</p>
        <div class="mt-4 flex justify-end">
            <button id="closeModal" class="bg-red-500 text-white px-4 py-2 rounded-lg">Close</button>
        </div>
    </div>
  </div> -->
  <div id="popup" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center  z-30 modal-back {{showPopup ? '' : 'hidden'}}">
    <div class="popup-modal rounded-lg p-6 relative model-content m-4">
      <form [formGroup]="popupForm" (ngSubmit)="onPopupSubmit()">
        <button type="button" class="absolute top-2 right-2 text-gray-400 hover:text-gray-600 bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" (click)="showPopup = false">
          <span class="sr-only">Close menu</span>
          <!-- Heroicon name: outline/x -->
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        <div class="">
          <div class="flex flex-row justify-center">
            <div class="sm:mt-5">
              <p class="lg:text-3xl text-2xl leading-none tracking-tighter text-white mb-5 sm:text-xl sm:mb-3" style="letter-spacing: normal;text-align: center;max-width: 702px;color: #1A2B48;">Do you want a free consultation to invest in Ethiopia?</p>
              <p class="  lg:text-3xl  leading-none tracking-tighter text-white mb-5 lg:text-lg" style="letter-spacing: normal;text-align: center;max-width: 702px;color: #1A2B48;">Leave your number and email, and one of our experts will get in touch</p>
            </div>
          </div>
          <div class="md:mt-5">
            <div class="flex flex-row justify-center">
              <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-x-4 gap-y-6 items-center w-4/5 sm:w-full">
                <input type="text" name="name" formControlName="name" autocomplete="false" placeholder="Enter your name"
                class="placeholder-gray-300 dark:placeholder-gray-600 w-full p-3 border border-gray-300 rounded outline-none focus:bg-gray-50 dark:bg-night-600 dark:border-gray-500 dark:text-gray-100 {{popupForm.controls['name'].invalid && (popupForm.controls['name'].dirty || popupForm.controls['name'].touched) ? 'error-class': ''}} "  required/>
                <input type="email" name="email" formControlName="email" autocomplete="false" placeholder="Enter your email"
                        class="placeholder-gray-300 dark:placeholder-gray-600 w-full p-3 border border-gray-300 rounded outline-none focus:bg-gray-50 dark:bg-night-600 dark:border-gray-500 dark:text-gray-100 {{popupForm.controls['email'].invalid && (popupForm.controls['email'].dirty || popupForm.controls['email'].touched) ? 'error-class': ''}}" required/>  
              </div>
              
            </div>
            <div class="flex flex-row justify-center mt-5">
              <div class="gap-x-4 gap-y-6 grid grid-cols-1 items-center md:grid-cols-1 sm:w-4/5 xl:grid-cols-1 xl:w-1/2 md:w-1/2 sm:w-full">
                <div formArrayName="phone" *ngFor="let phone of phone.controls; let i = index">
                  <input [formControlName]="i" name="number" type="number" inputmode="numeric" placeholder="Enter your number"
                class="placeholder-gray-300 dark:placeholder-gray-600 {{popupForm.controls['phone'].invalid && (popupForm.controls['phone'].dirty || popupForm.controls['phone'].touched) ? 'error-class': ''}} w-full p-3 border border-gray-300 rounded outline-none focus:bg-gray-50 dark:bg-night-600 dark:border-gray-500 dark:text-gray-100" required/>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <button type="submit" class="h-[45.33px] md:w-1/3 mt-3 md:mt-8 flex-none rounded-md bg-primary-500 px-4 py-2.5 text-sm text-white hover:bg-primary-600 sm:w-full">Submit</button>
        </div>
      </form>
    </div>
  </div>
</body>
<div *ngIf="showPopupToast" id="toast-success" class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 fixed top-5 right-5" role="alert" style="
    z-index: 9999;
">
    <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"></path>
        </svg>
        <span class="sr-only">Check icon</span>
    </div>
    <div class="ms-3 text-sm font-normal">Inquiry Sent! Our team will be in touch shortly.</div>
    <button (click)="showPopupToast = false" type="button" class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" aria-label="Close">
        <span class="sr-only">Close</span>
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path>
        </svg>
    </button>
</div>