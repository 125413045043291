import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Property } from '../models/property.model';
import { Router } from '@angular/router';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { Subject } from 'rxjs';
import { Observable, throwError, catchError } from 'rxjs';

const propertyNotifier = new Subject<void>();

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(private http: HttpClient, private router: Router) { }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
      return throwError('A client-side error occurred. Please check your internet connection and try again.');
    } else {
      
      if (error.status === 0) {
        return throwError('Unable to connect to the server. Please check your internet connection.');
      } else {
        return throwError('An unexpected server-side error occurred.');
      }
    }
  }

  @Cacheable({ cacheBusterObserver: propertyNotifier })
  getFilterProperty(data:any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post<Property>(`${environment.apiUrl}/properties/retrieve_filter`,data).pipe(
        catchError(this.handleError)
    )};
  }

  @Cacheable({ cacheBusterObserver: propertyNotifier })
  getAll(data:any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post<Property>(`${environment.apiUrl}/properties/retrieve_all`,data).pipe(
        catchError(this.handleError)
    )};
  }

  @Cacheable({ cacheBusterObserver: propertyNotifier })
  getAllAdmin(data:any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post<Property>(`${environment.apiUrl}/properties/retrieve_all_admin`,data).pipe(
        catchError(this.handleError)
    )};
  }

  @Cacheable({ cacheBusterObserver: propertyNotifier })
  getAdminFilter(data:any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post<Property>(`${environment.apiUrl}/properties/retrieve_admin_filter`,data).pipe(
        catchError(this.handleError)
    )};
  }

  @Cacheable({ cacheBusterObserver: propertyNotifier })
  getPublished() {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Property[]>(`${environment.apiUrl}/properties/find/published`).pipe(
        catchError(this.handleError)
    )};
  }

  @Cacheable({ cacheBusterObserver: propertyNotifier })
  getPublishedOptAdmin() {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Property[]>(`${environment.apiUrl}/properties/find/published_opt_admin`).pipe(
        catchError(this.handleError)
    )};
  }

  

  @Cacheable({ cacheBusterObserver: propertyNotifier })
  getPublishedOpt() {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Property[]>(`${environment.apiUrl}/properties/find/published_opt`).pipe(
        catchError(this.handleError)
    )};
  }

  @Cacheable({ cacheBusterObserver: propertyNotifier })
  getPublishedCount() {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Property[]>(`${environment.apiUrl}/properties/find/get_count`).pipe(
        catchError(this.handleError)
    )};
  }

  @Cacheable({ cacheBusterObserver: propertyNotifier })
  getUserPublished(data:any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post<Property[]>(`${environment.apiUrl}/properties/find/get_user_published`,data).pipe(
        catchError(this.handleError)
    )};
  }

  @Cacheable({ cacheBusterObserver: propertyNotifier })
  getPublishedItems() {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Property[]>(`${environment.apiUrl}/properties/find/published_items`).pipe(
        catchError(this.handleError)
    )};
  }

  

  @Cacheable({ cacheBusterObserver: propertyNotifier })
  getOnePublished(id: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Property>(`${environment.apiUrl}/properties/find/published/${id}`).pipe(
        catchError(this.handleError)
    )};
  }

  @Cacheable({ cacheBusterObserver: propertyNotifier })
  getPropertyListByCategory(slug: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Property>(`${environment.apiUrl}/properties/findByCategory/${slug}`).pipe(
        catchError(this.handleError)
    )};
  }


  @Cacheable({ cacheBusterObserver: propertyNotifier })
  getFeatured() {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
    return this.http.get<Property[]>(`${environment.apiUrl}/properties/find/featured`).pipe(
      catchError(this.handleError)
    )};
  }

  get(id: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get<Property>(`${environment.apiUrl}/properties/${id}`).pipe(
        catchError(this.handleError)
    )};
  }

  getProperty(data: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post<Property>(`${environment.apiUrl}/properties/get_property`,data).pipe(
        catchError(this.handleError)
    )};
  }

  @CacheBuster({ cacheBusterNotifier: propertyNotifier })
  create(data: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post<Property>(`${environment.apiUrl}/properties`, data).pipe(
        catchError(this.handleError)
    )};
  }

  createMany(data: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post<Property>(`${environment.apiUrl}/properties/many`, data).pipe(
        catchError(this.handleError)
    )};
  }

  @CacheBuster({ cacheBusterNotifier: propertyNotifier })
  update(id: any, data: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.put<Property>(`${environment.apiUrl}/properties/${id}`, data).pipe(
        catchError(this.handleError)
    )};
  }

  @CacheBuster({ cacheBusterNotifier: propertyNotifier })
  delete(id: any) {
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.delete(`${environment.apiUrl}/properties/${id}`).pipe(
        catchError(this.handleError)
    )};
  }

  upload(id: any, file: any, cover = false) {
    const formData: FormData = new FormData();

    formData.append('image', file);
    return this.http.post(`${environment.apiUrl}/properties/upload/${id}/${cover}`, formData).pipe(
      catchError(this.handleError)
    ).subscribe((res) => {
      this.router.navigateByUrl("/dashboard/properties");
    })
  }

  uploadFloorplan(id: any, file: any) {
    const formData: FormData = new FormData();

    formData.append('floorplan_images', file);
    return this.http.post(`${environment.apiUrl}/properties/uploadfloorplan/${id}`, formData).pipe(
      catchError(this.handleError)
    ).subscribe((res) => {
      this.router.navigateByUrl("/dashboard/properties");
    })
  }

  convertETB(amount: any) {
  
    if (navigator.onLine === false) {
      console.error('Unable to connect to the server. Please check your internet connection.');
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.get(`${environment.apiUrl}/properties/convert/USD/ETB/${amount}`).pipe(
        catchError(error => {
          console.error('Error in ConvertETB service:', error);
          return throwError('An unexpected error occurred. Please try again later.');
        })
      );
    }
  }
  
  removeImage(data: any){
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post(`${environment.apiUrl}/property_images/remove`, data).pipe(
        catchError(this.handleError)
    )};
  }
  removeFloorPlanImage(data: any){
    if (navigator.onLine === false) {
      return throwError('Unable to connect to the server. Please check your internet connection.');
    } else {
      return this.http.post(`${environment.apiUrl}/floorplan_images/remove`, data).pipe(
        catchError(this.handleError)
    )};
  }
}
