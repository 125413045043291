import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.css']
})
export class ImageSliderComponent implements OnInit {

  @Input() slides: any[] = [];
  @Input() id: any;

  constructor(private router: Router) { }
  touchStartX = 0;
  touchEndX = 0;
  currentIndex: number = 0;
  timeoutId?: number;
  imageLoaded: boolean = false;


  ngOnInit(): void {
    // this.resetTimer();
  }

  ngOnDestroy() {
    window.clearTimeout(this.timeoutId);
  }
  // resetTimer() {
  //   if (this.timeoutId) {
  //     window.clearTimeout(this.timeoutId);
  //   }
  //   this.timeoutId = window.setTimeout(() => this.goToNext(), 3000);
  // }

  goToPrevious(): void {
    const isFirstSlide = this.currentIndex === 0;
    const newIndex = isFirstSlide
      ? this.slides.length - 1
      : this.currentIndex - 1;

    // this.resetTimer();
    this.currentIndex = newIndex;
  }

  goToNext(): void {
    const isLastSlide = this.currentIndex === this.slides.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex + 1;

    // this.resetTimer();
    this.currentIndex = newIndex;
  }

  goToSlide(slideIndex: number): void {
    // this.resetTimer();
    this.currentIndex = slideIndex;
  }

  //getCurrentSlideUrl() {
    //console.log(this.slides);
    //const cloudfrontUrl = 'https://d32nivq0vevj5l.cloudfront.net';
    //const currentSlideUrl = this.slides?.[this.currentIndex]?.url;
    //const cloudfrontSlideUrl = currentSlideUrl.replace('https://le-property-photos.s3.us-east-2.amazonaws.com', cloudfrontUrl);

    //return `url('${cloudfrontSlideUrl}')`;
  //}


  onTouchStart(event: TouchEvent): void {
    this.touchStartX = event.touches[0].clientX;
  }

  onTouchEnd(event: TouchEvent): void {
    this.touchEndX = event.changedTouches[0].clientX;
    this.handleSwipe();
  }

  handleSwipe(): void {
    const swipeDistance = this.touchStartX - this.touchEndX;

    if (swipeDistance > 50) {
      // Right to left swipe (next slide)
      this.goToNext();
    } else if (swipeDistance < -50) {
      // Left to right swipe (previous slide)
      this.goToPrevious();
    }
  }

  getCurrentSlideUrl() {
    //console.log(this.slides)
    //console.log(this.slides?.[this.currentIndex]?.url)
    return `url('${this.slides?.[this.currentIndex]?.url}')`;
  }

  goToDetails() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/site/property-details/' + this.id])
    );
  
    window.open(url, '_blank');
    // this.router.navigateByUrl('/site/property-details/' + this.id);
  }
}
