import { Component } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { LoaderService } from './core/services/loader.service';
import { NavigationCancel, NavigationError, NavigationStart, Router } from '@angular/router';
import { MetaService } from 'src/app/core/services/meta.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Live Ethio';
  showLoader = false;

  constructor(private metaService: MetaService, public themeService: ThemeService, private loaderService: LoaderService,
    private router: Router) {}

  ngOnInit() {

    //this.router.events.subscribe((event) => {
      //if (event instanceof NavigationEnd) {
        //this.updateMetaTags(event.url);
      //}
    //});

    this.loaderService.isLoaderShown.subscribe(isLoaderShown => this.showLoader = isLoaderShown);
    this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationStart) {
        this.updateMetaTags(routerEvent.url);
        this.loaderService.showLoader();
      } 
      // else if (routerEvent instanceof NavigationEnd) {
      //   this.loaderService.hideLoader();
      // } 
      else if (routerEvent instanceof NavigationCancel) {
        this.loaderService.hideLoader();
        // Handle cancel
      } else if (routerEvent instanceof NavigationError) {
        this.loaderService.hideLoader();
        // Handle error
      }
    });
    //this.updateMetaTags();
  }

  updateMetaTags(url: string) {
    if (url === '/site/about-us') {
      this.metaService.updateTitle('About Us | Live Ethio Real Estate');
      this.metaService.updateDescription('A real estate company with a purpose. Our mission is to help you find your ideal property to rent, sale, manage in Addis Ababa, Ethiopia');
      this.metaService.updateURL('https://livingethio.com/site/about-us');
      this.metaService.updateImage('https://le-assets.s3.us-east-2.amazonaws.com/bg/le-logo.png');
    } else if (url === '/other-page') {
      // Update meta tags for other pages
    }
    // Add conditions for other pages as needed
  }

  onDeactivate() {
    document.body.scrollTop = 0;
    // Alternatively, you can scroll to top by using this other call:
    // window.scrollTo(0, 0)
  }

  
}
